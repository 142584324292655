<template>
  <div class="view time-info">
    <div class="view-heading">
      <h3 class="view-title">{{ $t('system.time_info') }}</h3>
    </div>
    <div class="view-body">
      <el-tooltip :content="$t('system.estimated_time')">
        <el-tag
            class="border-0"
            color="#00acc1"
            effect="dark"
        >
          {{ timeSums.estimated ? timeSums.estimated.slice(0, -3) : '00:00' }}
        </el-tag>
      </el-tooltip>
      <b>-</b>
      <el-tooltip :content="$t('system.worked_time')">
        <el-tag
            class="border-0"
            color="#007c91"
            effect="dark"
        >
          {{ timeSums.worked ? timeSums.worked.slice(0, -3) : '00:00' }}
        </el-tag>
      </el-tooltip>
      <b>=</b>
      <el-tooltip :content="$t('system.remaining_time')">
        <el-tag
            :color="timeSums.remaining && timeSums.remaining.startsWith('-') ? '#b71c1c' : '#4caf50'"
            class="border-0"
            effect="dark"
        >
          {{ timeSums.remaining ? timeSums.remaining.slice(0, -3) : '00:00' }}
        </el-tag>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeInfo',
  props: {
    timeSums: {
      type: Object,
      default: () => {
        return {
          estimated: null,
          worked: null,
          remaining: null,
        };
      },
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
</style>
