<template>
  <div class="view">
    <div class="view-heading">
      <h3 class="view-title">{{ $t('system.sub_projects') }}</h3>
    </div>
    <div class="view-body">
      <el-tree
          :data="subprojects"
          :props="defaultProps"
          @node-click="handleNodeClick"
          class="filter-tree"
          default-expand-all
      >
      <span
          class="custom-tree-node"
          slot-scope="{ node, data }"
      >
        <router-link :to="{ path: '/project/' + data.id}">
          <span>{{ node.label }}</span>
        </router-link>
      </span>
      </el-tree>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SubProject',
  data() {
    return {
      subprojects: [],
      loading: true,
      defaultProps: {
        children: 'children',
        label: 'name',
      },
    };
  },
  beforeMount() {
    this.getSubprojectsTree();
  },
  methods: {
    handleNodeClick(data) {
      this.$router.push({name: this.route, params: {projectId: data.id}});
    },
    getSubprojectsTree() {
      this.loading = true;
      axios.get(`subprojects_tree/${this.$route.params.projectId}`)
          .then((response) => {
            response.data.subprojects.sort(function (a, b) {
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            this.subprojects = response.data.subprojects;
            this.loading = false;
          });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.el-tree {
  .el-tree-node__label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
