<template>
  <div class="view-container">
    <div class="container-fluid p-0">
      <div class="row no-gutters">
        <div
            class="col-12 col-lg-8 brd-right"
            v-loading="loading"
        >
          <div class="view">
            <div class="view-heading d-flex justify-content-between">
              <h3 class="view-title">{{ $t('system.overview') }}</h3>
              <el-button
                  @click="setMode('edit')"
                  size="small"
                  type="primary"
                  v-if="mode === 'read' && ifHasPermission(['pm-edit-project'])"
              >
                {{ $t('system.edit') }}
              </el-button>
            </div>
            <el-form class="container-fluid">
              <form-generator
                  :backend_errors="backend_errors"
                  :data="data"
                  :dictionaries="dictionaries"
                  :inputRequiredEmpty="inputRequiredEmpty"
                  :mode="mode"
                  :schema="schema.containers"
                  v-if="schema && schema.containers"
              >
              </form-generator>
              <div v-if="mode === 'edit'">
                <div class="row">
                  <div class="col-xl-4 col-12 ml-auto">
                    <div class="d-flex justify-content-end mb-3">
                      <el-button @click="setMode('read')">{{ $t('system.cancel') }}</el-button>
                      <el-button
                          :loading="updateLoading"
                          @click="update"
                          type="primary"
                      >
                        {{ $t('system.save') }}
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <TimeInfo :timeSums="timeSums" />
          <SubProject />
          <Members v-if="ifHasPermission(['pm-member-read'])" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import SubProject from './SubProject.vue';
import formGenerator from '../../components/vue-form-generator/FromGenerator.vue';
import notification from '../../notification/notify';
import projectTreeBuilder from '../../mixin/projectsTreeBuilder';
import TimeInfo from './TimeInfo.vue';

export default {
  name: 'ProjectOverview',
  mixins: [projectTreeBuilder],
  components: {
    TimeInfo,
    formGenerator,
    SubProject,
    Members: () => import('./Members.vue'),
  },
  data() {
    return {
      timeSums: {
        estimated: null,
        worked: null,
        remaining: null,
      },
      backend_errors: {},
      dictionaries: {},
      inputRequiredEmpty: [],
      data: {},
      schema: {},
      loading: true,
      updateLoading: false,
    };
  },
  computed: {
    ...mapGetters(['project/project']),
    ...mapGetters('project', [
      'getChangedStatus',
      'getRefreshChangedStatus',
      'mode',
    ]),
  },
  watch: {
    'getRefreshChangedStatus': function () {
      if (this._self['getRefreshChangedStatus']) {
        let vm = this;
        this.$store.dispatch('project/changeVal', {
          param: 'status_id',
          new_val: vm._self['getChangedStatus'],
        }).then(vm.data = vm._self['project/project']);
        // this.fetchData()
        this.$store.commit('project/SET_REFRESH_CHANGED_STATUS', false);
        this.$store.dispatch('generator/update', {field: 'fieldSelect', refresh: true});
      }
    },
    mode(newValue) {
      if (newValue) {
        this.fetchData();
      }
    },
  },
  async beforeMount() {
    await this.setMode('read');
    this.fetchData();
    this.$store.commit('SET_ACTIVE', 'overview');
  },
  methods: {
    ...mapActions('project', ['setMode', 'refreshProject']),
    async fetchData(options = []) {
      this.loading = true;
      const headers = {
        platform: 'web',
        mode: this.mode,
      };
      await axios.get(`project/${this.$route.params.projectId}`, {
        headers: headers,
      })
          .then((response) => {
            this.timeSums = response.data.timeSums;
            if (options.length > 0) {
              options.forEach(option => {
                this[option] = response.data[option];
              });
            } else {
              Object.keys(response.data).forEach(key => {
                this[key] = response.data[key];
              });
            }
            if (this.dictionaries && this.dictionaries.projects) {
              this.dictionaries.projects = this.buildProjectTree(this.dictionaries.projects);
            }
            this.$store.dispatch('project/addProject', response.data.data);
            this.loading = false;
          })
          .catch((error) => {
            console.log('error', error);
            notification.notify(
                this.$t('notify.error'),
                this.$t(error),
                'error');
          });
    },
    update() {
      let headers = {
        platform: 'web',
        mode: this.mode,
      };
      this.checkValidation().then(() => {
        this.updateLoading = true;
        const method = this.mode === 'create' ? 'post' : 'put';
        axios[method](`project/${this.$route.params.projectId}`, this.data, {
          params: {
            tracker_id: 3,
          },
          headers: headers,
        }).then((response) => {
          this.updateLoading = false;
          this.setMode('read');
          this.refreshProject();
          notification.notify(
              this.$t('notify.success'),
              this.$t(response.data.message),
              'success');
        }).catch((error) => {
          this.updateLoading = false;
          notification.notify(
              this.$t('notify.error'),
              this.$t(error.data.message),
              error.type);
        });
      });
    },
  },
};
</script>
